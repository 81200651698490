<template>
  <div class="mortgage-other-detail">
    <div class="d-flex align-items-center px-2 py-4">
      <fd-button v-if="goBackRoute" class="circle mr-1" @click="goBack"
        ><i class="fas fa-chevron-left"></i
      ></fd-button>
      <h2>Other Method Mortgage Detail</h2>
    </div>

    <!-- ======================== Mortgage Detail ======================== -->
    <div class="bordered-card p-2 mb-3">
      <div class="row p-2">
        <display-data
          :class="displayDataCSS"
          label="Mortgage Option"
          :content="mortgage.mortgageCategory"
        ></display-data>
        <display-data
          :class="displayDataCSS"
          label="Remark"
          :content="mortgage.remark"
        ></display-data>
      </div>
    </div>

    <!-- ========================= Buyer Detail ========================== -->
    <reservation-info
      class="bordered-card p-3 mb-3"
      :projectPurchase="projectPurchase"
    ></reservation-info>
    <!-- ========================= Agent Detail ========================= -->
    <agent-info
      v-if="agent"
      class="bordered-card p-3 mb-3"
      :agencyUser="agent"
    ></agent-info>

    <!-- ========================= Unit Detail ========================== -->
    <div class="bordered-card p-2 mb-3">
      <h4 class="mx-2 my-2">Unit Detail</h4>

      <div v-if="dataLoaded" class="row p-2">
        <display-data :class="displayDataCSS" label="Project">
          {{ projectData.project.name }}
        </display-data>
        <display-data :class="displayDataCSS" label="Developer">
          {{ projectData.project.developer.name }}
        </display-data>
        <display-data :class="displayDataCSS" label="Project Type">
          {{ projectData.project.projectType }}
        </display-data>
        <display-data :class="displayDataCSS" label="Property Category">
          {{ projectData.project.propertyCategory }}
        </display-data>
        <display-data :class="displayDataCSS" label="Unit Plot No.">
          {{ reservation.unitPlot }}
        </display-data>
      </div>
    </div>
  </div>
</template>

<script>
import _omit from "lodash/omit";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    ReservationInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Reservation/ReservationInfo"
      ),
    AgentInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/AgentInfo"
      )
  },
  mixins: [],
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    goBackRoute: {
      type: Object
    },
    getOtherMortgageAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      mortgage: {},
      agent: {},
      reservation: {},
      projectData: {},
      projectPurchase: {},

      dataLoaded: false,
      displayDataCSS: "col-12 sm-col-6 md-col-4 lg-col-3"
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getOtherMethodMortgageDetail();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    goBack() {
      this.$router.replace(this.goBackRoute);
    },
    async getOtherMethodMortgageDetail() {
      try {
        this.dataLoaded = false;
        let data = await this.getOtherMortgageAPI(this.id);
        this.mortgage = this._.cloneDeep(
          _omit(data, "projectPurchaseMortgage")
        );
        this.reservation = this._.cloneDeep(
          data.projectPurchaseMortgage.projectPurchase
            .projectPurchaseReservation
        );
        this.projectPurchase = this._.cloneDeep(
          data.projectPurchaseMortgage.projectPurchase
        );
        this.agent = this._.cloneDeep(
          data.projectPurchaseMortgage.projectPurchase.agent
        );
        this.projectData = this._.cloneDeep({
          project: data.projectPurchaseMortgage.projectPurchase.project,
          projectUnitType:
            data.projectPurchaseMortgage.projectPurchase.projectUnitType,
          projectUnit: data.projectPurchaseMortgage.projectPurchase.projectUnit
        });
        this.dataLoaded = true;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>

<style lang="scss"></style>
